<!--
 * @Description:
 * @Autor: Zhongyu
 * @Date: 2021-09-01 15:29:17
 * @LastEditors: Lizy
 * @LastEditTime: 2021-12-21 10:06:42
-->
<template>
  <div>
    <div class="install-tutorial">
      <div class="install-tutorial-banner">
        <img :src="$utils.picturePrefix(banners[0].image)" />
      </div>
      <div class="install-tutorial-title">安装单教程</div>

      <div class="install-tutorial-tab">
        <div
          class="tab-item"
          v-for="(item, index) in navList"
          :key="index"
        >
          <el-select 
            :popper-append-to-body="false"
            v-model="item.navTitle" 
            :placeholder="item.name"
            @focus="focusNav(item)" 
            @change="changeNav(item.navTitle,index)"
          >
            <el-option-group
              v-for="group in mainList"
              :key="group.id"
              :label="group.name"
            >
              <el-option
                v-for="item in group.tutorialList"
                :key="item.id"
                :label="item.title"
                :value="item.title"
              >
              </el-option>
            </el-option-group>
          </el-select>
        </div>
      </div>
      <template>
        <div class="install-tutorial-img">
          <img src="../../assets/img/installTutorial/img-video-bg.png" />
          <video-player
            class="video-player vjs-custom-skin"
            ref="videoPlayer"
            :playsinline="true"
            :options="playerOptions"
            v-if="video != ''"
          ></video-player>
          <div class="no-video" v-else>
            <img src="../../assets/img/installTutorial/no-video.png" />
            <p>暂无视频</p>
          </div>
        </div>
        <!-- sources: [{ src: $utils.picturePrefix(data[index].video) }] -->

        <div class="desc" v-if="info != ''">
          <div class="desc-title">
            安装简介
          </div>

          <div class="desc-body">
            {{ info }}
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      aMap: null,
      userGuideList: [],
      playerOptions: {
        playbackRates: [0.5, 1.0, 1.5, 2.0], //可选择的播放速度
        autoplay: false, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "14:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [],
        index: 0,
        poster: "", //你的封面地址
        // width: document.documentElement.clientWidth,
        notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, //当前时间和持续时间的分隔符
          durationDisplay: true, //显示持续时间
          remainingTimeDisplay: false, //是否显示剩余时间功能
          fullscreenToggle: true, //全屏按钮
        },
      },
      banners: [],
      data: [],
      index: 0,
      navTitle: '',
      navList: [], //一级标题
      mainList: [], //下拉数据
      video: "", //视频地址
      info: "", //教程介绍
      navIndex: 0,
    };
  },
  mounted() {
    // 获取一级标题
    this.$api.getTutorialTypeOne().then(res => {
      if (res.code == 200) {
        this.navList = res.data;
        this.$api
          .getTutorialType({
            id: res.data[0].id,
          })
          .then(ress => {
            console.log(ress);
            if (ress.code == 200) {
              let mainList = ress.data;
              res.data[0].navTitle = mainList[0].tutorialList[0].title;
              this.navList = res.data;
              this.mainList = mainList;
              let video = mainList[0].tutorialList[0].video;
              this.video = video;
              this.playerOptions.sources = [{src: this.$utils.picturePrefix(video)}];
              this.info = mainList[0].tutorialList[0].info;
            } else {
              this.$message({
                type: "error",
                message: res.msg,
                duration: 2000,
              });
            }
          });
      } else {
        this.$message({
          type: "error",
          message: res.msg,
          duration: 2000,
        });
      }
    });
    // this.getTutorial();
    this.getBanner();
  },
  methods: {
    // 点击一级导航栏
    focusNav(item) {
      this.getPopList(item.id);
    },
    // 一级导航栏发生改变
    changeNav(name,index) {
      let { mainList } = this;
      // console.log(navList)
      // console.log(mainList,name,index)
      this.navIndex = index
      mainList.map(item => {
        item.tutorialList.map(it => {
          if (it.title == name) {
            this.video = it.video;
            this.playerOptions.sources = [{src: this.$utils.picturePrefix(it.video)}];
            this.info = it.info;
            console.log(it.video)
          }
        })
      })

    },
    // 获取下拉数据
    getPopList(id) {
      this.mainList = [];
      this.$api
        .getTutorialType({
          id: id,
        })
        .then((res) => {
          if (res.code == 200) {
            this.mainList = res.data;
          } else {
            this.$message({
              type: "error",
              message: res.msg,
              duration: 2000,
            });
          }
        });
    },
    bannerHeight() {
      let width = window.innerWidth;
      let getClass = document.getElementsByClassName(
        "install-tutorial-banner"
      )[0];

      // eslint-disable-next-line no-self-assign
      width < 1200 ? (width = 1200) : (width = width);
      let height = (width / 1920) * 360;
      console.log(getClass, height);
      return (getClass.style.height = height + "px");
    },
    getBanner() {
      this.$api.getBanner({ type: 7 }).then(res => {
        this.banners = res.data;
        setTimeout(() => {
          this.bannerHeight();
        }, 0);
        // this.playerOptions.sources =
        // [src: $utils.picturePrefix(data[index].video)]
      });
    },
    // getTutorial() {
    //   this.$api.getTutorial().then(res => {
    //     this.data = res.data;
    //     this.index = -1;
    //     if (res.data.length > 0) {
    //       this.index = 0;
    //       this.tabsChange(0);
    //     }
    //   });
    // },
    // tabsChange(index) {
    //   if (
    //     this.data.length > index &&
    //     index >= 0 &&
    //     this.data[index].video != ""
    //   ) {
    //     this.playerOptions.sources = [
    //       {
    //         src: this.$utils.picturePrefix(this.data[index].video),
    //       },
    //     ];
    //   } else {
    //     this.playerOptions.sources = [
    //       {
    //         src: this.$utils.picturePrefix(this.data[index].video),
    //       },
    //     ];
    //   }

    //   this.index = -1;
    //   setTimeout(() => {
    //     this.index = index;
    //   }, 1);
    // },
  },
  watch: {
    // 监听导航栏index变化，清空其他select选中状态
    'navIndex': function (newValue, oldValue) {
      console.log(newValue, oldValue)
      let { navList } = this;
      console.log(navList)
      navList.map((item,i) => {
        if (newValue != i) {
          item.navTitle = null
        }
      })
      this.navList = navList;
    }
  }
};
</script>

<style lang="less">
@import "./index.less";
</style>
